import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import ReactToPrint, { useReactToPrint } from 'react-to-print'

import { withStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Checkbox, Grid } from '@material-ui/core'
import ModalLoginExpirado from '../../components/ModalLoginExpirado'

import Input from '../../components/Input'
import Button from '../../components/ButtonComponent'
import Icon from '../../components/Icon'
import OverlayLoading from '../../components/OverlayLoading'
import CallbackMessage from '../../components/CallbackMessage'

import Boleto from './Boleto2'

import Logo from '../../assets/img/logo-3.svg'
import creditCardIcon from '../../assets/img/credit-card-icon.svg'
import cardFlags from '../../assets/img/creditcard.png'
import scissors from '../../assets/img/scissors.svg'
import checkCircle from '../../assets/img/check-circle.svg'
import checkCircleWhite from '../../assets/img/check-circle-white.svg'
import alertCircle from '../../assets/img/alert-circle.svg'
import alertCircleWhite from '../../assets/img/alert-circle-white.svg'

import faturasService from '../../services/faturasService'
import boletoService from '../../services/boletoService'

import { formatDate } from '../../utils/dates'
import { numberToCurrency } from '../../utils/functions'
import { boletoMask, cpfCnpjMask, cepMask } from '../../utils/strings'
import validateFields from '../../utils/validateFields'
import Select from '../../components/SelectComponent'

import classes from './styles.module.scss'
import smallScreen from '../../utils/smallScreen'
import Switch from '../../components/Switch'
import loginService from '../../services/loginService'
function FaturaBoleto() {
  const CustomCheckbox = withStyles({
    root: {
      color: '#E8EBF1',
      marginTop: 10,
      width: 0,
      borderRadius: 4,
      '&$checked': {
        color: '#812990'
      }
    },
    checked: {}
  })((props) => <Checkbox color='default' {...props} />)

  const history = useHistory()
  const params = useParams()
  const location = useLocation()

  const { checkLogin } = loginService
  const { idFatura, idBoleto } = params
  // const [boletoConfig, setBoletoConfig] = useState({});
  const [openLoginExpirado, setOpenLoginExpirado] = useState(false)
  const { consultaFatura, pagamentoBrasPag } = faturasService
  const { consultaBoleto, consultaConfiguracoes } = boletoService
  const isSmallScreen = smallScreen()

  const [loading, setLoading] = useState(false)

  const [callbackShown, setCallbackShown] = useState(false)
  const [callbackDuration, setCallbackDuration] = useState(6000)
  const [callbackMessage, setCallbackMessage] = useState('')
  const [callbackErrorList, setCallbackErrorList] = useState([])
  const [callbackType, setCallbackType] = useState('')

  const [moreOptionsDisplay, setMoreOptionsDisplay] = useState(false)

  const [empresa, setEmpresa] = useState('')
  const [cnpjCpf, setCnpjCpf] = useState('')
  const [inscricaoEstadual, setInscricaoEstadual] = useState('')
  const [estado, setEstado] = useState('')
  const [endereco, setEndereco] = useState('')
  const [bairro, setBairro] = useState('')
  const [cidade, setCidade] = useState('')
  const [numeroDocumento, setNumeroDocumento] = useState('')
  const [dataEmissao, setDataEmissao] = useState('')
  const [dataVencimento, setDataVencimento] = useState('')
  const [itemFatura, setItemFatura] = useState([])
  const [valorTotal, setValorTotal] = useState('')
  const [dataPagamento, setDataPagamento] = useState('')
  const [nsu, setNsu] = useState('')
  const [boletoEncontrado, setBoletoEncontrado] = useState(false)
  const [formaPagamento, setFormaPagamento] = useState('')
  const [idSolicitacaoServico, setIdSolicitacaoServico] = useState(null)

  const hasServicoExtra = !!idSolicitacaoServico

  const [checkedDebit, setCheckedDebit] = useState(false)
  const [checkedCredit, setCheckedCredit] = useState(false)
  const [parcelar, setParcelar] = useState(false)

  const [cardNumber, setCardNumber] = useState('')
  const [cardName, setCardName] = useState('')
  const [cardValidity, setCardValidity] = useState('')
  const [cardSecurityCode, setCardSecurityCode] = useState('')

  const [digitableLine, setDigitableLine] = useState('')
  const [listQtdParcelas, setListQtdParcelas] = useState([])
  const [idQtdParcela, setIdQtdParcela] = useState(null)
  const [permiteParcelamento, setPermiteParcelamento] = useState(false)

  // 0 - Não pago
  // 1 - Pago por boleto
  // 2 - Pago por cartão
  // 3 - Pago por PIX
  // 4 - Vencida
  const [pageType, setPageType] = useState(0)

  const [boleto, setBoleto] = useState({
    linhaDigitavel: '',
    dataVencimento: '',
    beneficiario: '',
    agenciaCodigo: '',
    dataDocumento: '',
    numeroDocumento: '',
    especieDoc: '',
    aceite: '',
    dataProcessamento: '',
    nossoNumero: '',
    usoDoBanco: '',
    carteira: '',
    especie: '',
    quantidade: '',
    valor: '',
    valorDocumento: '',
    instrucao: '',
    desconto: '',
    juros: '',
    valorCobrado: '',
    nomePagador: '',
    cpfCnpjPagador: '',
    enderecoPagador: '',
    codBarras: '',
    cpfCnpjBeneficiario: '',
    enderecoBeneficiario: '',
    valorPago: '',
    contaCodigo: ''
  })

  // const loadConfigBoleto = useCallback(
  //   async idCliente => {
  //     await consultaConfiguracoes(idCliente).then(response => {
  //       if (response.status === 401) {
  //         return setOpenLoginExpirado(true);
  //       }
  //       if (response.data.length > 0) {
  //         return setBoletoConfig(response.data[0]);
  //       } else {
  //         setLoading(false);
  //         setCallbackType("error");
  //         setCallbackMessage("Não há configurações de boleto disponíveis.");
  //         setCallbackShown(true);
  //       }
  //     });
  //   },
  //   [consultaConfiguracoes]
  // );

  const loadBoleto = useCallback(
    async (boletoConfig) => {
      const response = await consultaBoleto(idBoleto)

      // if (response.status === 401) {
      //   setOpenLoginExpirado(true);
      //   setLoading(false);
      //   return;
      // }
      if (response.status === 400) {
        setBoletoEncontrado(false)
        setLoading(false)
        setCallbackType('error')
        setCallbackMessage('Erro!')
        setCallbackErrorList([response.data.error])
        setCallbackShown(true)
        return
      }
      const { data } = response

      setBoletoEncontrado(true)
      setDigitableLine(data.linha_digitavel)

      const data_criacao = data.data_criacao.substring(0, 10)

      //data criacao em data do documento e data do processamento

      setBoleto({
        linhaDigitavel: boletoMask(data.linha_digitavel),
        dataVencimento: formatDate(data.data_vencimento),
        beneficiario: data.Beneficiario,
        agenciaCodigo: `${data.AgenciaCodigo}/${
          data.ContaCodigo || ''
        }`,
        dataDocumento: formatDate(data_criacao),
        numeroDocumento: data.numero_titulo_beneficiario,
        especieDoc: data.Especie,
        aceite: data.codigo_aceite,
        dataProcessamento: formatDate(data_criacao),
        nossoNumero: data.numero_titulo_cliente,
        usoDoBanco: '',
        carteira: data.numero_carteira,
        especie: 'R$',
        quantidade: data.Quantidade,
        valor: numberToCurrency(data.valor_original).substring(
          3,
          numberToCurrency(data.valor_original).length
        ),
        valorDocumento: numberToCurrency(data.valor_original).substring(
          3,
          numberToCurrency(data.valor_original).length
        ),
        instrucao: data.intrucoes,
        desconto: numberToCurrency(data.valor_desconto).substring(
          3,
          numberToCurrency(data.valor_desconto).length
        ),
        juros: numberToCurrency(data.valor_juros).substring(
          3,
          numberToCurrency(data.valor_juros).length
        ),
        valorCobrado: numberToCurrency(
          Number(data.valor_original) -
            Number(data.valor_desconto) +
            Number(data.valor_juros)
        ).substring(
          3,
          numberToCurrency(
            Number(data.valor_original) -
              Number(data.valor_desconto) +
              Number(data.valor_juros)
          ).length
        ),
        nomePagador: data.pagador_nome,
        // cpfCnpjPagador: cnpjCpf,
        enderecoPagador: `${data.pagador_cidade} ${
          data.pagador_bairro && data.pagador_bairro !== ''
            ? '- ' + data.pagador_bairro
            : ''
        }
      ${' '}${
          data.pagador_endereco && data.pagador_endereco !== ''
            ? '- ' + data.pagador_endereco
            : ''
        }
      ${' '}${
          data.pagador_cep && cepMask(data.pagador_cep) !== false
            ? '- ' + cepMask(data.pagador_cep)
            : ''
        }`,
        codBarras: data.codigo_barra_numerico,
        cpfCnpjBeneficiario: cpfCnpjMask(boletoConfig.beneficiario_cnpj),
        enderecoBeneficiario: [
          `${boletoConfig.logradouro}`,
          `${boletoConfig.bairro} - ${boletoConfig.municipio}/${
            boletoConfig.uf
          } - CEP: ${
            boletoConfig.cep && cepMask(boletoConfig.cep) !== false
              ? cepMask(boletoConfig.cep)
              : ''
          }`
        ],
        valorPago: numberToCurrency(data.valor_pago)
      })
    },
    [consultaBoleto, idBoleto]
  )

  const loadFatura = useCallback(async () => {
    const response = await consultaFatura(idFatura)

    // if (response.status === 401) {
    //   setOpenLoginExpirado(true);
    //   setLoading(false);
    //   return;
    // }
    if (response.status === 400) {
      setLoading(false)
      setCallbackType('error')
      setCallbackMessage('Erro!')
      setCallbackErrorList([response.data.error])
      setCallbackShown(true)
      return
    }
    if (response.status === 200) {
      const {
        fatura_id,
        cliente_bairro,
        cliente_cidade,
        cliente_cnpj_cpf,
        cliente_endereco,
        cliente_inscricao_estadual,
        cliente_nome,
        cliente_uf,
        data_emissao,
        data_vencimento,
        item_fatura,
        valor_total,
        status_pagamento,
        forma_pagamento,
        id_empresa,
        nsu,
        permite_parcelamento,
        select_parcelas,
        id_solicitacao_servico
      } = response.data

      let { data_pagamento, data_pagamento_baixa } = response.data

      if (data_pagamento === "0000-00-00 00:00:00") {
        data_pagamento = null;
      }

      if (data_pagamento_baixa === "0000-00-00") {
        data_pagamento_baixa = null;
      }

      if (data_pagamento !== null || data_pagamento_baixa !== null) {
        if (data_pagamento !== null) {
          setDataPagamento(formatDate(data_pagamento?.substring(0, 10)))
        } else {
          setDataPagamento(formatDate(data_pagamento_baixa?.substring(0, 10)))
        }
        setNsu(nsu)

        if (forma_pagamento === 'BOLETO') {
          setPageType(1)
        }
        if (
          forma_pagamento === 'CARTÃO' ||
          forma_pagamento === 'CARTÃO DE CRÉDITO' ||
          forma_pagamento === 'CARTÃO DE DÉBITO'
        ) {
          setPageType(2)
        }
        if(forma_pagamento === 'PIX'){
          setPageType(3);
        }
      }

      const newItemFatura = item_fatura.map((item) => {
        return {
          ...item,
          item_valor: numberToCurrency(item.item_valor)
        }
      })

      setEmpresa(cliente_nome)
      setCnpjCpf(cpfCnpjMask(cliente_cnpj_cpf))
      // setBoleto((prevState) => ({
      //   ...prevState,
      //   cpfCnpjPagador: cpfCnpjMask(cliente_cnpj_cpf)
      // }))
      setInscricaoEstadual(cliente_inscricao_estadual)
      setEstado(cliente_uf)
      setEndereco(cliente_endereco)
      setBairro(cliente_bairro)
      setCidade(cliente_cidade)
      setNumeroDocumento(fatura_id)
      setDataEmissao(formatDate(data_emissao))
      setDataVencimento(formatDate(data_vencimento))
      setItemFatura(newItemFatura)
      setValorTotal(numberToCurrency(valor_total))
      setFormaPagamento(forma_pagamento)
      setPermiteParcelamento(permite_parcelamento === 1 ? true : false)
      setIdSolicitacaoServico(id_solicitacao_servico)
      if (permite_parcelamento === 1) {
        setListQtdParcelas(select_parcelas)
      }

      if (idBoleto && status_pagamento !== 'PAGO') {
        let boletoConfig = {}

        if (id_empresa) {
          const response = await consultaConfiguracoes(id_empresa)
          if (response.data.length > 0) {
            boletoConfig = response.data[0]
          }
          if (response.status === 200) {
            await loadBoleto(boletoConfig)
          } else {
            setLoading(false)
            setCallbackType('error')
            setCallbackMessage('Não há configurações de boleto disponíveis.')
            setCallbackShown(true)
          }
        }
      }
    }
  }, [consultaFatura, idFatura, loadBoleto, consultaConfiguracoes])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
        await loadFatura()
        setLoading(false)
    })()
  }, [loadFatura, loadBoleto])

  const handleChange = (event) => {
    if (event.target.name === 'debit') {
      setCheckedDebit(event.target.checked)
      if (event.target.checked === true) {
        setCheckedCredit(false)
      }
    }

    if (event.target.name === 'credit') {
      setCheckedCredit(event.target.checked)
      if (event.target.checked === true) {
        setCheckedDebit(false)
      }
    }
  }

  const handleFormSubmit = async () => {
    setLoading(true)
    let tipoCartao = checkedCredit
      ? 'CreditCard'
      : checkedDebit
      ? 'DebitCard'
      : ''

    let hasErrors = false

    const fieldsToValidade = [
      {
        label: 'numerodocartao',
        value: cardNumber
      },
      {
        label: 'nomeimpressonocartao',
        value: cardName
      },
      {
        label: 'validade',
        value: cardValidity
      },
      {
        label: 'codigodeseguranca',
        value: cardSecurityCode
      },
      {
        label: 'tipodocartao',
        value: tipoCartao
      }
    ]

    const fieldsErrors = validateFields(fieldsToValidade)

    if (fieldsErrors.length !== 0) {
      setLoading(false)
      hasErrors = true
      setCallbackType('error')
      setCallbackMessage('Erro!')
      setCallbackErrorList(fieldsErrors)
      setCallbackShown(true)
    }

    if (hasErrors) return

    const response = await pagamentoBrasPag(
      idFatura,
      tipoCartao,
      cardNumber,
      cardValidity,
      cardSecurityCode,
      'http://www.braspag.com.br',
      cardName.toUpperCase(),
      checkedCredit && permiteParcelamento ? idQtdParcela : null
    )
    // if (response.status === 401) {
    //   setOpenLoginExpirado(true);
    //   setLoading(false);
    //   return;
    // }
    if (response.status === 200 || response.status === 201) {
      setLoading(false)
      setCallbackType('success')
      setCallbackMessage('Pago!')
      setCallbackShown(true)
      setCallbackDuration(2000)
    } else if (response.status === 400) {
      setLoading(false)
      setCallbackType('error')
      setCallbackMessage('Erro!')
      if (response.data.error)
        setCallbackErrorList(Object.values(response.data.error))
      else setCallbackErrorList(['Ocorreu um erro'])
      setCallbackShown(true)
    }
    setLoading(false)
  }

  const handleCopy = () => {
    const digitableLine = document.querySelector('#digitableLine')
    digitableLine.select()
    document.execCommand('copy')
  }

  function MoreOptions({
    options,
    lineId,
    callback,
    handleMouseDown,
    displayHeader = false
  }) {
    let onClick = (e) => {
      let data
      if (displayHeader) {
        data = {
          action: e.target.dataset.action
        }
      } else
        data = {
          id: lineId,
          action: e.target.dataset.action
        }
      callback(data)
    }

    return (
      <>
        {displayHeader && (
          <div className={`modal-options-header ${classes.modalOptionsHeader}`}>
            <ul className='modal-options-list-header'>
              {options.map((option, index) => (
                <React.Fragment key={index}>
                  <li
                    className='modal-options-list-item'
                    data-action={option.action}
                    onMouseDown={(e) => {
                      handleMouseDown()
                      onClick(e)
                    }}
                  >
                    <span className='modal-options-list-item-icon'>
                      <Icon name={option.icon} color='#0a2346' />
                    </span>
                    <span className='modal-options-list-item-label'>
                      {option.label}
                    </span>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}
        {!displayHeader && (
          <div className={`modal-options ${classes.modalOptions}`}>
            <ul className='modal-options-list'>
              {options.map((option, index) => (
                <React.Fragment key={index}>
                  <li
                    className='modal-options-list-item'
                    data-action={option.action}
                    onMouseDown={(e) => {
                      handleMouseDown()
                      onClick(e)
                    }}
                  >
                    <span className='modal-options-list-item-icon'>
                      <Icon name={option.icon} />
                    </span>
                    <span className='modal-options-list-item-label'>
                      {option.label}
                    </span>
                  </li>
                </React.Fragment>
              ))}
            </ul>
          </div>
        )}
      </>
    )
  }

  const printableRef = useRef()

  const handlePrintFatura = useReactToPrint({
    content: () => printableRef.current,
    bodyClass: 'print'
  })

  const onMoreOptions = (action) => {
    if (action.action === 'imprimir') {
      return handlePrintFatura()
    }

    if (action.action === 'logout') {
      window.localStorage.clear()
      return history.replace('/')
    }
  }

  let headerOptions = [
    {
      action: 'imprimir',
      label: 'Imprimir',
      icon: 'printer-blue'
    }
  ]

  const boletoRef = useRef()

  // const handleBoletoPdfDownload = () => {
  //   const doc = new jsPDF();

  //   const boletoPdf = document.querySelector("#boleto");
  //   doc.html(boletoPdf, {
  //     filename: "boleto.pdf",
  //     callback: function (doc) {
  //       doc.save();
  //     },
  //   });
  // };

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      setCallbackShown(false)
    }
    if (reason === 'timeout') {
      setCallbackShown(false)
    }
    if (callbackType === 'success') {
      location.state?.goToHome ? history.push('/home') : history.goBack()
    }
  }

  const handleCardNumberChange = useCallback((event) => {
    setCardNumber(event.target.value)
  }, [])

  function MobileBoletoDescription() {
    return (
      <>
        {boletoEncontrado && (
          <>
            <h1 className={classes.paymentTitle}>
              Se preferir pague via boleto bancário
            </h1>
            <div className={classes.boletoDescription}>
              Abaixo está o código de barras do boleto, mas se precisar pode
              salvar o boleto completo em PDF clicando no botão mais abaixo.
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <>
      {loading && <OverlayLoading />}
      {!isSmallScreen && (
        <div
          className={classes.menuWrapper}
          onClick={() => setMoreOptionsDisplay((prev) => !prev)}
        >
          <Icon name='hamburger' size={22} color='#EF4066' className='icon' />
          {moreOptionsDisplay && (
            <MoreOptions
              handleMouseDown={() => setMoreOptionsDisplay(false)}
              options={headerOptions}
              callback={onMoreOptions}
              displayHeader
            />
          )}
        </div>
      )}
      <div
        id='printFatura'
        className='session-container user-register-container'
      >
        <span className='session-container-header' ref={printableRef}>
          <div className={`${classes.wrapper}`}>
            <section className={classes.panel}>
              <div className={classes.logoWrapper}>
                <img src={Logo} alt='' />
              </div>
              <div className={classes.infoWrapper}>
                <span>A HUB SOLUCOES EM GESTAO E TECNOLOGIA -</span>
                <span>CONTABILHUB LTDA</span>
                <span>CNPJ: 52.237.580/0001-39</span>
                <span>comercial@contabilhub.com.br</span>
              </div>
            </section>
            <Grid
                container
                className={classes.header}
                spacing={2}
              justify='center'
            >
              <Grid item xs={12} sm={6} md={6}>
                <h1>FATURA</h1>
              </Grid>
              <Grid item className={classes.headerInfo} xs={12} sm={6} md={6}>
                <div className={classes.headerInfoLabels}>
                  <span>Número do Documento:</span>
                  <span>Data de emissão:</span>
                  <span>Data de vencimento:</span>
                </div>
                <div className={classes.headerInfoValues}>
                  <span>{numeroDocumento}</span>
                  <span>{dataEmissao}</span>
                  <span>{dataVencimento}</span>
                </div>
                {pageType === 4 && (
                  <div className={classes.headerInfoStatus}>
                    <img src={alertCircle} alt='' />
                    <span className={classes.headerInfoStatusDue}>VENCIDO</span>
                  </div>
                )}
                {
                  (pageType === 1 || pageType === 2 || pageType === 3)
                   && (
                    <div className={classes.headerInfoStatus}>
                      <img src={checkCircle} alt='' />
                      <span>PAGO</span>
                    </div>
                  )
                }
              </Grid>
            </Grid>
            <section className={classes.invoiceDescription}>
              <div className={classes.companyInfo}>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyName}`}
                >
                  <span className={classes.infoTitle}>Empresa:</span>
                  <span className={classes.infoValue}>{empresa}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyAddress}`}
                >
                  <span className={classes.infoTitle}>Endereço:</span>
                  <span className={classes.infoValue}>{endereco}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyDistrict}`}
                >
                  <span className={classes.infoTitle}>Bairro:</span>
                  <span className={classes.infoValue}>{bairro}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyCpfCnpj}`}
                >
                  <span className={classes.infoTitle}>CNPJ/CPF:</span>
                  <span className={classes.infoValue}>{cnpjCpf}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyStateRegistration}`}
                >
                  <span className={classes.infoTitle}>Inscrição Estadual:</span>
                  <span className={classes.infoValue}>{inscricaoEstadual}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyCity}`}
                >
                  <span className={classes.infoTitle}>Cidade:</span>
                  <span className={classes.infoValue}>{cidade}</span>
                </div>
                <div
                  className={`${classes.invoiceDescriptionFields} ${classes.companyState}`}
                >
                  <span className={classes.infoTitle}>Estado:</span>
                  <span className={classes.infoValue}>{estado}</span>
                </div>
              </div>
              <div className={classes.serviceInfo}>
                <h1 className={classes.serviceInfoTitle}>
                  Descrição de Serviços
                </h1>
                <table className={classes.serviceInfoTable}>
                  <thead>
                    <tr className={classes.alignLeft}>
                      <th className={classes.alignLeft}>ID</th>
                      <th className={classes.alignLeft}>Descrição</th>
                      <th className={classes.alignRight}>Valor (R$)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemFatura.map((item) => {
                      let list = []
                      for (let i = 0; i < item.item_quantidade; i++) {
                        list.push(
                          <tr>
                            <td className={classes.alignLeft}>
                              {item.fatura_item_id}
                            </td>
                            <td className={classes.alignLeft}>{item.item}</td>
                            <td className={classes.alignRight}>
                              {item.item_valor}
                            </td>
                          </tr>
                        )
                      }
                      return list
                    })}
                    <tr>
                      <td colSpan={2} className={classes.alignRight}>
                        TOTAL
                      </td>
                      <td className={classes.alignRight}>{valorTotal}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            {pageType !== 0 && (
              <div
                className={`${classes.paymentLabel} ${
                  pageType === 4 ? classes.paymentAlert : classes.paymentSuccess
                }`}
              >
                <img
                  src={pageType === 4 ? alertCircleWhite : checkCircleWhite}
                  alt=''
                />
                <span className={classes.paymentLabelText}>
                  {dataPagamento && (
                    <>
                      Fatura paga com {pageType === 1 && 'boleto '}
                      {pageType === 2 &&
                        formaPagamento === 'CARTÃO DE CRÉDITO' &&
                        'cartão de crédito '}
                      {pageType === 2 &&
                        formaPagamento === 'CARTÃO DE DÉBITO' &&
                        'cartão de débito '}
                      {pageType === 2 &&
                        formaPagamento === 'CARTÃO' &&
                        'cartão '}
                      {pageType === 3 && 'pix '}
                      {dataPagamento && ` em ${dataPagamento}`}
                      <br />
                      {nsu && `NSU ${nsu}`}
                    </>
                  )}
                  {/* {pageType === 3 && <>Fatura vencida</>} */}
                </span>
              </div>
            )}
            {pageType === 0 && (
              <>
                <section
                  className={`${
                    boletoEncontrado
                      ? classes.payment
                      : classes.paymentWithoutBillet
                  } no-print`}
                  style={{
                    paddingBottom: hasServicoExtra && '0px'
                  }}
                >
                  <h1 className={classes.paymentTitle} style={{paddingBottom: '0px'}}>
                    {hasServicoExtra &&
                      boletoEncontrado &&
                      'Pague com boleto'}
                    {!hasServicoExtra && (
                      <>
                      Pague com Boleto
                  </>
                    )}
                  </h1>
                  {!hasServicoExtra && (
                    <div className={classes.paymentInfo}>
                    </div>
                  )}
                  {boletoEncontrado && (
                    <div className={classes.scissorsIcon}>
                      <img src={scissors} alt='' />
                    </div>
                  )}
                </section>
                {boletoEncontrado && (
                  <>
                    {isSmallScreen && <MobileBoletoDescription />}
                    <div className='page-break' />
                    <section className={classes.bankSlip}>
                      <div className={`${classes.bankSlipLine} no-print`}>
                        <h1>Copiar linha digitável</h1>
                        <Grid
                          container
                          spacing={2}
                          className={classes.lineActions}
                        >
                          <Grid item xs={12} sm={12} md={8}>
                            <div className={classes.bankSlipInputContainer}>
                              <Input
                                id='digitableLine'
                                readOnly
                                value={digitableLine}
                              />
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            container
                            justify='center'
                            alignItems='flex-end'
                          >
                            <Button
                              className='primary-outline'
                              icone='copy'
                              text='Copiar'
                              onClick={handleCopy}
                              style={{ fontWeight: 500 }}
                            />
                          </Grid>
                        </Grid>
                        <div style={{ height: isSmallScreen ? 50 : 0 }} />
                      </div>
                      <div
                        id='boleto'
                        className={classes.bankSlipContainer}
                        style={{ display: isSmallScreen ? 'none' : 'inherit' }}
                      >
                        <Boleto boleto={boleto} ref={boletoRef} cnpjCpfPagador={cnpjCpf} />
                      </div>
                    </section>
                    <section className={`${classes.bankSlipActions} no-print`}>
                      {/* <Button
                className="primary-outline"
                icone="download-thin"
                iconeColor="#812990"
                text="Salvar em PDF"
                onClick={handleBoletoPdfDownload}
              /> */}

                      <ReactToPrint
                        bodyClass='print'
                        trigger={() => (
                          <Button
                            className='primary-outline-small'
                            icone='printer'
                            iconeColor='#812990'
                            text='Imprimir'
                          />
                        )}
                        content={() => boletoRef.current}
                      />
                    </section>
                  </>
                )}
              </>
            )}
          </div>
        </span>
      </div>

      {pageType !== 0 && (
        <div className={`button-area ${classes.buttonOutsideArea}`}>
          <span>
            <Button
              type='button'
              text='Voltar'
              className='btn-success'
              onClick={() => history.goBack()}
            />
          </span>
        </div>
      )}
      <CallbackMessage
        open={callbackShown}
        duration={callbackDuration}
        errorList={callbackErrorList}
        handleClose={handleClose}
        message={callbackMessage}
        type={callbackType}
      />
      <ModalLoginExpirado open={openLoginExpirado} />
    </>
  )
}

export default FaturaBoleto
