import Axios from "./Axios";

const conciliacaoService = {
  getListaConciliacao: async (
    idConta,
    page = null,
    perPage = null,
    data_de = null,
    data_ate = null
  ) => {
    const result = await Axios.get(
      `/lista-conciliacao?id_conta_bancaria=${idConta}`,
      {
        params: {
          page: page,
          per_page: perPage,
          data_de,
          data_ate,
        },
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  getListaRecebimento: async (
    idEmpresa,
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    valor = null
  ) => {
    const result = await Axios.get(
      `/lista-recebimento-conciliacao?id_empresa=${idEmpresa}`,
      {
        params: {
          page: page,
          order_by: orderBy,
          order_by_type: orderByType,
          per_page: perPage,
          search_term: searchTerm,
          valor,
        },
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  getListaPagamento: async (
    idEmpresa = "",
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    valor = null
  ) => {
    const result = await Axios.get(
      `/lista-conciliacao-pagamento?id_empresa=${idEmpresa}`,
      {
        params: {
          page: page,
          order_by: orderBy,
          order_by_type: orderByType,
          per_page: perPage,
          search_term: searchTerm,
          valor,
        },
      }
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  conciliarLote: async (id_lancamento, ids_lancamentos, usarIntegracao) => {
    const result = await Axios.post("conciliar-em-lote", {
      id_lancamento,
      ids_lancamentos,
      usar_integracao: usarIntegracao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  conciliar: async (id_lancamento) => {
    const result = await Axios.get(
      `conciliar-lancamento?id_lancamento=${id_lancamento}`
    )
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  conciliarNovoRecebimento: async (
    id_empresa,
    arquivo,
    descricao,
    id_categoria,
    data_vencimento,
    valor,
    id_cliente,
    id_centro_custo,
    id_lancamento,
    usarIntegracao,
    lancamentoRecorrente,
    idConta
  ) => {
    const response = await Axios.post("conciliar-novo-recebimento", {
      id_empresa: id_empresa,
      arquivo: arquivo,
      descricao: descricao,
      id_categoria: id_categoria,
      data_vencimento: data_vencimento,
      valor: valor,
      id_cliente: id_cliente,
      id_centro_custo: id_centro_custo,
      id_lancamento: id_lancamento,
      lancamento_recorrente: lancamentoRecorrente,
      id_conta_bancaria: idConta,
      usar_integracao: usarIntegracao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  conciliarNovoPagamento: async (
    id_empresa,
    arquivo,
    descricao,
    id_categoria,
    data_vencimento,
    valor,
    id_fornecedor,
    id_centro_custo,
    id_lancamento,
    usarIntegracao,
    lancamentoRecorrente,
    idConta
  ) => {
    const response = await Axios.post("conciliar-novo-pagamento", {
      id_empresa,
      arquivo,
      descricao,
      id_categoria,
      data_vencimento,
      valor,
      id_fornecedor,
      id_centro_custo,
      id_lancamento,
      lancamento_recorrente: lancamentoRecorrente,
      id_conta_bancaria: idConta,
      usar_integracao: usarIntegracao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  valorPendente: async (idConta) => {
    const result = await Axios.get(`valor-pendente-conciliacao/${idConta}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  estornarConciliacao: async (idLancamento) => {
    const result = await Axios.post(`estorna-conciliacao/${idLancamento}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
};

export default conciliacaoService;
