import Axios from "./Axios";

const contasBancariasService = {
  getContasBancarias: async (
    page = null,
    orderBy = null,
    orderByType = null,
    perPage = null,
    searchTerm = null,
    id_empresa = null
  ) => {
    const result = await Axios.get("/lista-conta", {
      params: {
        page: page,
        order_by: orderBy,
        order_by_type: orderByType,
        per_page: perPage,
        search_term: searchTerm,
        id_empresa,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return result;
  },
  addContaBancaria: async ({
    idEmpresa,
    idBanco,
    agencia = null,
    contaCorrente = null,
    digito = null,
    contaContabil = null,
    saldoInicial = null,
    nomeConta = null,
    idIntegracao = null,
    usarIntegracao = null,
  }) => {
    const add = await Axios.post("/cadastra-conta", {
      id_banco: idBanco,
      agencia: agencia,
      conta_corrente: contaCorrente,
      digito: digito,
      conta_contabil: contaContabil,
      id_empresa: idEmpresa,
      saldo_inicial: saldoInicial,
      nome_conta: nomeConta,
      id_integracao: idIntegracao,
      usar_integracao: usarIntegracao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return add;
  },
  atualizaContaBancaria: async ({
    id,
    idEmpresa,
    idBanco,
    agencia = null,
    contaCorrente = null,
    digito = null,
    contaContabil = null,
    nomeConta = null,
    idIntegracao = null,
    usarIntegracao = null,
  }) => {
    const response = await Axios.post(`/atualiza-conta/${id}`, {
      id_banco: idBanco,
      agencia,
      conta_corrente: contaCorrente,
      digito,
      conta_contabil: contaContabil,
      id_empresa: idEmpresa,
      nome_conta: nomeConta,
      id_integracao: idIntegracao,
      usar_integracao: usarIntegracao,
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });
    return response;
  },
  consultaContaBancaria: async (id) => {
    const response = await Axios.get(`/consulta-conta/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  getBancos: async () => {
    const response = await Axios.get("/seleciona-banco", {})
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  removeConta: async (id) => {
    const response = await Axios.get(`/remover-conta/${id}`)
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
  selecionaConta: async (idEmpresa) => {
    const response = await Axios.get("/seleciona-conta-bancaria", {
      params: {
        id_empresa: idEmpresa,
      },
    })
      .then(({ ...response }) => {
        return response;
      })
      .catch(({ response }) => {
        return response;
      });

    return response;
  },
};

export default contasBancariasService;
